
import { BAutocomplete } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import { defineComponent, shallowRef, computed } from 'vue';
import SearchIcon from '../../../../../../components/icons/SearchIcon';

const frameworks = [
	'Angular',
	'Angular 2',
	'Aurelia',
	'Backbone',
	'Ember',
	'jQuery',
	'Meteor',
	'Node.js',
	'Polymer',
	'React',
	'RxJS',
	'Vue.js'
];

export default defineComponent({
	name: 'autocomplete-example',
	components: {
		BAutocomplete,
		BField
	},
	setup() {
		const selectedFrameworks = shallowRef([]);
		const description = computed(() => selectedFrameworks.value.join(', '));

		return {
			SearchIcon,
			frameworks,
			selectedFrameworks,
			description
		};
	}
});
